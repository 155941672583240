'use client'

import { type PressEvent } from '@react-types/shared'
import { type LayoutProps, motion } from 'framer-motion'
import { type ReactNode, forwardRef, useMemo } from 'react'
import { Button as AriaButton } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'

import { Link } from '../../../i18n/Navigation'

const MotionLink = motion(Link)
const MotionButton = motion(AriaButton)

type ButtonProps = {
  readonly children: ReactNode
  readonly href?: string
  readonly className?: string
  readonly disabled?: boolean
  readonly icon?: boolean
  readonly ariaLabel?: string
  readonly variant?: 'filled' | 'outlined' | 'text'
  readonly submit?: boolean
  readonly reset?: boolean
  readonly layout?: LayoutProps['layout']
  readonly layoutRoot?: LayoutProps['layoutRoot']
  readonly onClick?: (() => void) | ((e: PressEvent) => void)
  readonly 'data-testid'?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      href,
      className,
      disabled,
      icon,
      ariaLabel,
      variant = 'filled',
      onClick,
      submit,
      reset,
      layout,
      layoutRoot,
      ...props
    },
    ref,
  ) => {
    const classNames = useMemo(
      () =>
        twMerge(
          'transition-colors duration-300 ease-in-out',
          variant === 'filled' && 'bg-secondary text-regular-dark',
          variant === 'outlined' &&
            'bg-transparent text-secondary before:absolute before:inset-0 before:border-2 before:border-secondary before:content-[""]',
          variant === 'text' && 'bg-transparent text-secondary',
          icon
            ? 'relative flex h-12 w-12 flex-row items-center justify-center rounded-full p-2 before:rounded-full'
            : 'relative rounded-md px-4 py-2 before:rounded-md',
          disabled && 'opacity-50',
          !disabled && 'active:opacity-80',
          className,
        ),
      [className, disabled, icon, variant],
    )

    const content =
      typeof children === 'string' ? (
        <p className='uppercase'>{children}</p>
      ) : icon ? (
        <span className='inline-block'>{children}</span>
      ) : (
        children
      )

    return href != null ? (
      <MotionLink
        layout={layout ?? true}
        layoutRoot={layoutRoot ?? false}
        href={href}
        className={classNames}
        aria-label={ariaLabel}
        data-testid={props['data-testid']}
        aria-disabled={disabled}
      >
        {content}
      </MotionLink>
    ) : (
      <MotionButton
        layout={layout ?? true}
        layoutRoot={layoutRoot ?? false}
        className={classNames}
        aria-label={ariaLabel}
        isDisabled={disabled}
        onPress={onClick}
        ref={ref}
        data-testid={props['data-testid']}
        type={submit ? 'submit' : reset ? 'reset' : 'button'}
      >
        {content}
      </MotionButton>
    )
  },
)

Button.displayName = 'Button'

export { Button }
