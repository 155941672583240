'use client'

import { createContext, useContext, useEffect, useMemo } from 'react'

export type Breadcrumbs = { title?: string; page: string }[]

export const PageTitleContext = createContext<
  (val: { title?: string; breadcrumbs: Breadcrumbs }) => void
>(() => {})

export const usePageTitle = (title: string = '', breadcrumbs: Breadcrumbs = []) => {
  const setPageTitle = useContext(PageTitleContext)

  useMemo(() => {
    setPageTitle({ title, breadcrumbs })
  }, [setPageTitle, title, breadcrumbs])

  useEffect(() => {
    setPageTitle({ title, breadcrumbs })
  }, [setPageTitle, title, breadcrumbs])
}
