/* eslint-disable no-restricted-imports */
import { type UrlObject } from 'url'

import { type NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { default as NextLink } from 'next/link'
import { useRouter as useNextRouter, usePathname } from 'next/navigation'
import { type ComponentProps, type Ref, forwardRef, useMemo } from 'react'

import { joinPaths } from '../helpers/resolveUrl'

import { AVAILABLE_LOCALES, useTranslation } from './useTranslation'

export const getNewHref = (
  href: string | UrlObject,
  locale?: string,
  inpLocale?: string | false,
) => {
  const strHref = href.toString()

  if (/^https?\:\/\//.test(strHref)) return href
  if (inpLocale === false) return href
  if (inpLocale != null) return joinPaths(`/${inpLocale}`, strHref)
  if (locale != null) return joinPaths(`/${locale}`, strHref)

  return href
}

export const useRouter = () => {
  const nextRouter = useNextRouter()
  const pathname = usePathname()
  const { locale } = useTranslation()

  return useMemo(
    () => ({
      ...nextRouter,
      push: (href: string, inpLocale?: string | false, options?: NavigateOptions) =>
        nextRouter.push(getNewHref(href, locale, inpLocale).toString(), options),
      replace: (href: string, inpLocale?: string | false, options?: NavigateOptions) =>
        nextRouter.replace(getNewHref(href, locale, inpLocale).toString(), options),
      switchLocale: (locale: string) => {
        let basePath = pathname

        if (AVAILABLE_LOCALES.some(l => pathname.startsWith(`/${l}`))) {
          basePath = pathname.split('/').slice(2).join('/')
        }

        const newHref = getNewHref(basePath, locale)
        nextRouter.replace(newHref.toString())
      },
    }),
    [locale, nextRouter, pathname],
  )
}

const Link = forwardRef(
  (
    { children, href, locale: inpLocale, ...props }: ComponentProps<typeof NextLink>,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    const { locale } = useTranslation()

    const newHref = getNewHref(href, locale, inpLocale)

    return (
      <NextLink {...props} ref={ref} href={newHref}>
        {children}
      </NextLink>
    )
  },
)
Link.displayName = 'Link'
export { Link }
