'use client'

import { useSearchParams } from 'next/navigation'
import { signIn } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Button } from '../../../../components/input/Button/Button'
import { usePageTitle } from '../../../../components/layout/MainLayout/PageTitle'
import { useRouter } from '../../../../i18n/Navigation'
import { useTranslation } from '../../../../i18n/useTranslation'
import { useUserStore } from '../../../../store/user'

export default function Index() {
  const { t } = useTranslation()
  usePageTitle(t.pages.login.title)

  const [signInLoading, setSignInLoading] = useState(false)
  const { user } = useUserStore()
  const searchParams = useSearchParams()

  const router = useRouter()

  useEffect(() => {
    const callbackUrl = searchParams.get('callbackUrl')

    if (user.id != null) {
      if (callbackUrl != null && !/^\/\w+\/login$/.test(callbackUrl)) {
        router.replace(callbackUrl)
      } else {
        router.replace('/')
      }
    }
  }, [router, searchParams, user])

  return (
    <>
      <div className='mt-[10vw] flex w-full flex-col items-center justify-center gap-4'>
        <p className='whitespace-pre-line text-center'>{t.pages.login.loginMessage}</p>

        <Button
          data-testid='login-button'
          disabled={signInLoading}
          onClick={() => {
            setSignInLoading(true)
            signIn('keycloak')
              .catch(() => toast.error(t.general.errors.login))
              .finally(() => setSignInLoading(false))
          }}
        >
          {t.pages.login.loginButton}
        </Button>
      </div>
    </>
  )
}
