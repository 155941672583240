/**
 * Joins paths together and removes double slashes.
 *
 * Similar to Node's `path.join`, but browser-compatible.
 *
 * @param paths the paths to join
 * @returns the joined path
 */
export const joinPaths = (...paths: (string | undefined)[]) => {
  const path = paths
    .filter(p => p)
    .join('/')
    .replace(/\/\/+/g, '/')

  return path.endsWith('/') ? path.slice(0, -1) : path
}

/**
 * Resolves a URL from a base URL and a path.
 *
 * Similar to Node's `path.resolve`, but browser-compatible and for URLs.
 *
 * @param baseUrl the base url to join paths on
 * @param paths the paths to join on the url
 * @returns the resolved url
 */
export const resolveUrl = (baseUrl: string, ...paths: (string | undefined)[]) => {
  const finalPath = joinPaths(...paths)

  return new URL(finalPath, baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl)
}
