import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { type User } from '../domain/user'

export type LocalUser = Partial<User> & { loading: boolean }

export type UserStore = {
  user: LocalUser
  setUser: (user: LocalUser) => void
}

export const useUserStore = create<UserStore>()(
  persist(
    set => ({
      user: { loading: false },
      setUser: user => set({ user }),
    }),
    {
      name: 'eva_user',
      skipHydration: true,
      partialize: state => ({ user: state.user }),
    },
  ),
)
